<template>
    <div class="map-card" :class="['card', autoWidth ? 'card-block': '', isHighlighted, typeClass]" @click="highlight">
        <div class="card-head">
            <div class="card-content head-content">
                <h5 v-if="category!==''" class="cat"> {{ category }}: </h5>
                <h5 class="sub-cat">{{الفئة الفرعية }}}</h5>
                <h3 class="title">{{ العنوان }}}</h3>
            </div>
        </div>
        <div class="card-content">
            <router-link :to="linkTo" class="btn btn-round more-info">المزيد من المعلومات      
               <span class="material-icons-outlined show-ltr ml-auto" aria-hidden="true" data-wg-notranslate="">navigate_next</span>
               <span class="material-icons-outlined show-rtl ml-auto" aria-hidden="true" data-wg-notranslate="">navigate_before</span>  
           </router-link>
           <card-share-button class="float-right" :link="linkTo"></card-share-button>
           <bookmark-button :parentClass="'card-body-icon'" :content="this.item" v-if="this.item!==null"></bookmark-button>
       </div>
   </div>
</template>

<script>
    import { getSubCategory } from '@/utils/lupe-helpers';
    import { getFieldValues, getFirstFieldValue } from '@/utils/helpers';

    export default {
        name: 'OrteMapCard',
        components: {
            CardShareButton: () => import('@/components/controls/CardShareButton.vue'),
            BookmarkButton: () => import('@/components/controls/BookmarkButton'),
        },
        props: {
            item: {
                type: Object,
            default: null,
                required: true
            },
            autoWidth: {
                type: Boolean,
            default: false,
            },
        },
        computed: {
            title(){
              var title = this.item.title.length>55?this.item.title.substring(0,55)+' ...':this.item.title;
              return title;
          },
          linkTo(){
            return "/orte/"+this.item.institution_id;
        },
        category(){
            var value = getFirstFieldValue(this.item,'kielrefugeecompass');
            return value!=null?value:'';
        },
        subCategory(){
         return getSubCategory(this.item,this.category);
     },
     typeClass(){
        if(this.category !== null && this.category !== '') {
        }
        else {
            return 'no-cat';
        }
        return this.category!==null?this.category.replace(/\s/g, '').replace(/[^A-Za-z0-9\-_]/g, ''):'';
    },
    isHighlighted(){
        if(this.item.hasOwnProperty("highlight") && this.item.highlight == true){
            return "highlighted";
        }
        return "not-highlighted"
    },
    address(){
        var address = getFieldValues(this.item,'adresse');
        if(address!==null){
            var gna = "";
            if(address.hasOwnProperty('street') && address.street!==null){
                gna = gna+address.street+'<br>';
            }
            if(address.hasOwnProperty('zipcode') && address.zipcode!==null){
                gna = gna+address.zipcode+', ';
            }
            if(address.hasOwnProperty('city') && address.city!==null){
                gna = gna+address.city;
            }
            return gna;
        }
        return "";
    }
},
methods:{
 getFieldValues,
 getFirstFieldValue, 
 highlight(event){
    this.$emit('highlight',{sender:'card', content: this.item});
}
}
}
</script>

<style lang="scss" scoped="">
    @import '@/scss/_variables.scss';

    .card {
        display: flex;
        position: relative;
        min-width: 285px;
        border: none;
        border-radius: 0px;
        margin-top: 15px;
        margin-right: 15px;
        margin-bottom: 15px;
        box-shadow: 1px 2px 10px 1px #00000040;
        cursor: pointer;
        border-radius: 0px 0px 0px 40px;
        height: 200px;

        &.no-cat {
            .card-head {
                background-color: #E8F6FC;
            }
            &.highlighted {
                border: 3px solid #aed7fc;
            }
            .card-img {
                border: 3px solid #C7E4FF;
            }
        }

        &.Freizeit{
            .card-head {
                background-color: #C7E4FF;
            }
            &.highlighted{
                border: 3px solid #90C9FF;
            }

            .card-img{
                border: 1px solid #90C9FF;
            }

            .card-img__icon{  
                fill: $primary;
            }
        }

        &.Beratung{
            .card-head {
                background-color: #FCCC9F;
            }
            &.highlighted{
                border: 3px solid #FA993F;
            }

            .card-img{
                border: 1px solid #FA993F;
            }

            .card-img__icon{  
                fill: $wb-meganta;
            }
        }

        &.Bildung{
            .card-head {
                background-color: #FDEBB6;
            }
            &.highlighted{
                border: 3px solid #FCD770;
            }

            .card-img{
                border: 1px solid #FCD770;
            }

            .card-img__icon{  
                fill: $wb-jade;
            }
        }

        &.Sprache{
            .card-head {
                background-color: #F6A7AB;
            }
            &.highlighted{
                border: 3px solid #ED4F58;
            }

            .card-img{
                border: 1px solid #ED4F58;
            }

            .card-img__icon{  
                fill: $wb-jade;
            }
        }

        &.EhrenamtEngagement{
            .card-head {
                background-color: #A4E6C7;
            }
            &.highlighted, &:focus-visible{
                border: 3px solid #46CC8D;
            }

            .card-img{
                border: 1px solid #46CC8D;
            }

            .card-img__icon{  
                fill: $wb-jade;
            }
        }

        .category {
            color: $primary;
        }

        .title {
            font-size: 16px;
            line-height: 24px;
            margin-top: 10px;
            margin-bottom: 20px;
            min-height: 26px;
            max-width: calc(100% - 48px);
        }

        .address{
            font-size: 14px;
        }

        .btn-next {
            position: absolute;
            right: 15px;
            bottom: 15px;
        }

        &:first-child {
            margin-left: 15px;
        }

        &-block {
            margin-right: 0;
            width: 100%;
        }
    }

    .cat, .sub-cat {
        text-transform: uppercase;
    }

    .more-info {
        position: absolute;
        bottom: 0;
        margin-bottom: 7px;
        margin-left: 15px;
        display: flex;
        height: 41px;
        align-items: center;
        text-transform: none;
        max-width: 150px;
        white-space: nowrap;
        justify-content: center;
    }

    .card-head {
        display: flex;
        height: 140px;
    }

    .head-content {
        margin-left: 15px;
        margin-top: 15px;
        margin-right: 15px;
        width: 100%;
    }

    body[dir="rtl"] {
        .card{
            margin-left: 15px;
            margin-right: 0;

            .btn-next {
                right: auto;
                left: 15px;
            }

            &.card-block {
                margin-left: 0;
            }

            &:first-child {
                margin-right: 15px;
            }
        }

        .head-content {
            margin-left: 0;
            margin-right: 20px;
        }

        .more-info {
            margin-left: 0;
            margin-right: 15px;
        }
    }
</style>
